var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black pl-3",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("title.agency.insuranceTypeList")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "v-col",
                { staticStyle: { "padding-right": "10px" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black float-right mr-4",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "70vw",
                        "max-width": "350px",
                        color: "next",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickCreate()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.register")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0", staticStyle: { width: "100%" } },
            [
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0" },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      "calculate-widths": "",
                      headers: _vm.headers,
                      items: _vm.records,
                      options: _vm.options,
                      "server-items-length": _vm.total,
                      loading: _vm.isLoading,
                      "loading-text": _vm.$t(
                        "description.insuranceTypeReading"
                      ),
                      "no-data-text": _vm.$t("error.insuranceTypeListNotFound"),
                      "footer-props": {
                        itemsPerPageText: "1ページあたりの行数",
                        itemsPerPageOptions: _vm.itemsPerPageOptions
                      }
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.options = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.edit",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass:
                                      "font-weight-black float-center",
                                    attrs: {
                                      color: "next",
                                      dark: "",
                                      ripple: "",
                                      link: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickEdit(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "header.insuranceTypeListTable.edit"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            ]
                          }
                        },
                        {
                          key: "item.fireFlg",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.checkFlg(item.fireFlg)) +
                                  "\n          "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showCreateFormsDialog,
              title: _vm.$t("label.createInsurancetype"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.register"),
              onClickNegativeButton: _vm.cancelCreate,
              onClickPositiveButton: _vm.createInsuranceType,
              targets: _vm.createTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditFormsDialog,
              title: _vm.$t("label.editInsurancetype"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEdit,
              onClickPositiveButton: _vm.updateInsuranceType,
              targets: _vm.editTargets
            }
          }),
          _c("ErrorDialog", { ref: "errorDialog" }),
          _c("CompletedDialog", { ref: "completedDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }