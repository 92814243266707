<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.insuranceTypeList') }}
          </p>
        </v-col>
        <v-col style="padding-right:10px">
          <!-- 登録 -->
          <v-btn
            class="font-weight-black float-right mr-4"
            align="center"
            width="70vw"
            max-width="350px"
            color="next"
            style="font-size:20px"
            dark
            @click="onClickCreate()"
            >{{ $t('button.register') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row style="width:100%" class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-data-table
            calculate-widths
            class="elevation-1"
            :headers="headers"
            :items="records"
            :options.sync="options"
            :server-items-length="total"
            :loading="isLoading"
            :loading-text="$t('description.insuranceTypeReading')"
            :no-data-text="$t('error.insuranceTypeListNotFound')"
            :footer-props="{
              itemsPerPageText: '1ページあたりの行数',
              itemsPerPageOptions: itemsPerPageOptions,
            }"
          >
            <!-- 編集ボタン -->
            <template v-slot:[`item.edit`]="{ item }" class="pa-0">
              <template>
                <v-chip
                  class="font-weight-black float-center"
                  color="next"
                  dark
                  ripple
                  link
                  @click="onClickEdit(item)"
                  >{{ $t('header.insuranceTypeListTable.edit') }}</v-chip
                >
              </template>
            </template>
            <template v-slot:[`item.fireFlg`]="{ item }" class="pa-0">
              {{ checkFlg(item.fireFlg) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- 登録ダイアログ -->
      <FormsDialog
        :showDialog="showCreateFormsDialog"
        :title="$t('label.createInsurancetype')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.register')"
        :onClickNegativeButton="cancelCreate"
        :onClickPositiveButton="createInsuranceType"
        :targets="createTargets"
      />
      <!-- 編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditFormsDialog"
        :title="$t('label.editInsurancetype')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEdit"
        :onClickPositiveButton="updateInsuranceType"
        :targets="editTargets"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
    </v-layout>
  </v-container>
</template>

<script>
import {} from '@/apis/agency/applicationForms';
import {
  InsuranceTypeListDisplayDefault,
  InsuranceTypeListTableHeader,
  MaxLength,
} from '@/lib/const';
import {
  getInsuranceTypeList,
  postInsuranceType,
  putInsuranceType,
} from '@/apis/agency/insuranceTypes';
import moment from 'moment';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  name: 'InsuranceTypeList',
  components: { FormsDialog, CompletedDialog, ErrorDialog },

  data() {
    return {
      headers: InsuranceTypeListTableHeader,
      records: [],

      // 申込書一覧取得オプション
      options: {
        page: InsuranceTypeListDisplayDefault.page,
        itemsPerPage: InsuranceTypeListDisplayDefault.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      },

      defualtTargets: [
        {
          value: null,
          targetName: 'name',
          type: 'text',
          label: this.$t('header.insuranceTypeListTable.name'),
          text: null,
          rule: `required|max:${MaxLength.InsuranceTypeName}`,
          key: 'name',
        },
        {
          value: null,
          targetName: 'fireFlg',
          type: 'checkBox',
          label: this.$t('header.insuranceTypeListTable.fireFlg'),
          text: this.$t('description.fireFlgDescription'),
          rule: '',
          key: 'fireFlg',
        },
        {
          value: null,
          targetName: 'sjId',
          type: 'text',
          label: this.$t('header.insuranceTypeListTable.sjId'),
          text: null,
          rule: `required|max:${MaxLength.InsuranceTypeId}`,
          key: 'sjId',
        },
      ],
      createTargets: [],
      editTargets: [],
      editId: null,

      // 1ページあたりの表示件数の選択肢
      itemsPerPageOptions: InsuranceTypeListDisplayDefault.itemsPerPageOptions,

      // 一覧取得件数
      total: 0,

      // ローディング中であるかどうか
      isLoading: true,

      showCreateFormsDialog: false,
      showEditFormsDialog: false,
    };
  },
  async mounted() {
    await this.getRecord();

    this.$set(this, 'isLoading', false);
  },
  methods: {
    onClickCreate() {
      this.$set(
        this,
        'createTargets',
        JSON.parse(JSON.stringify(this.defualtTargets))
      );
      this.$set(this, 'showCreateFormsDialog', true);
    },
    cancelCreate() {
      this.$set(this, 'showCreateFormsDialog', false);
    },
    async createInsuranceType(item) {
      const result = await postInsuranceType(item).catch(err => {
        console.log(err);
      });

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('success.created')
        );
        await this.getRecord();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('error.createFailed')
        );
      }
      this.$set(this, 'showCreateFormsDialog', false);
    },
    onClickEdit(item) {
      this.$set(this, 'editId', item.id);

      this.editTargets = JSON.parse(JSON.stringify(this.defualtTargets));
      this.editTargets.map(editTarget => {
        editTarget.value = item[editTarget.targetName];
        return editTarget;
      });

      this.$set(this, 'showEditFormsDialog', true);
    },
    cancelEdit() {
      this.$set(this, 'showEditFormsDialog', false);
    },
    async updateInsuranceType(item) {
      const result = await putInsuranceType(this.editId, item).catch(err => {
        console.log(err);
      });

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('success.updated')
        );
        await this.getRecord();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('error.updateFailed')
        );
      }
      this.$set(this, 'showEditFormsDialog', false);
    },
    async getRecord() {
      const insuranceTypeList = await getInsuranceTypeList();
      this.$set(
        this,
        'records',
        insuranceTypeList.map(insuranceType => {
          insuranceType.latestUpdate = moment(insuranceType.updatedAt).format(
            'YYYY/MM/DD'
          );
          return insuranceType;
        })
      );
      this.$set(this, 'total', insuranceTypeList.count);
    },
    checkFlg(boolean) {
      return boolean ? '○' : '-';
    },
  },
  computed: {},
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}
.v-data-table {
  overflow: hidden;
}
</style>
